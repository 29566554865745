import * as React from "react";
import * as ReactDOM from "react-dom";

import { ReceptionDetailListItem, ReceptionListItem } from "./interfaces";

import styles from "./view.less";
import { Accordion, Icon, Loader } from "semantic-ui-react";

import ReceptionExtraInfoView from "./extraInfoView";

import moment from "moment";

import { languages, WarehouseService } from "Services";
import GeneralLoader from "Utilities/GeneralLoader";
import { receptionWithPackageVariance } from "./filters";

declare var GlobalConfig: GlobalConfig;

export default class ReceptionView extends React.Component<
  { isEmployee: boolean; reception: ReceptionListItem },
  {
    isCollapseOpen: boolean;
    loadingDetails: boolean;
    formattedCreateDate: string;
    receptionDetails?: ReceptionDetailListItem[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isCollapseOpen: false,
      formattedCreateDate: moment(this.props.reception.CreatedDate)
        .format("HH:mm DD.MM.YYYY")
        .toString(),
      loadingDetails: false
    };
  }

  componentDidUpdate(prevProps: {
    isEmployee: boolean;
    reception: ReceptionListItem;
  }) {
    if (prevProps.reception.ReceptionID !== this.props.reception.ReceptionID) {
      this.setState({
        formattedCreateDate: moment(this.props.reception.CreatedDate)
          .format("HH:mm DD.MM.YYYY")
          .toString()
      });
    }
  }

  toggleCollapse = async () => {
    if (this.state.isCollapseOpen) {
      this.setState({ isCollapseOpen: false });
    } else if (!this.state.receptionDetails) {
      GeneralLoader.increase();
      this.setState({ loadingDetails: true });
      WarehouseService.getReceptionDetails(this.props.reception.ReceptionID)
        .then(res => {
          GeneralLoader.decrease();
          this.setState({
            receptionDetails: res,
            isCollapseOpen: true,
            loadingDetails: false
          });
        })
        .catch(() => {
          GeneralLoader.decrease();
          this.setState({ loadingDetails: false });
        });
    } else {
      this.setState({ isCollapseOpen: true });
    }
  };

  getIcon = () => {
    const { isCollapseOpen, loadingDetails } = this.state;
    if (loadingDetails) {
      return (
        <Loader
          active={loadingDetails}
          className="details-loader"
          size="medium"
        />
      );
    }
    return isCollapseOpen ? (
      <Icon size="big" name="chevron up" />
    ) : (
      <Icon size="big" name="chevron down" />
    );
  };

  render() {
    const { reception } = this.props;
    const { isCollapseOpen, formattedCreateDate } = this.state;
    return (
      <div
        className={`js-Tile single-reception-container ${styles.view} ${
          receptionWithPackageVariance(reception) ? "highlight" : ""
        }`}
      >
        <Accordion className="Tile-upper">
          <div className="col-lg-2 col-sm-6 ">
            <span className="Tile-upperTitle">
              {languages("LABEL_RECEPTION_ID")}
            </span>
            <div className="Tile-upperContent">
              {reception.ReceptionID ? reception.ReceptionID : "-"}
            </div>
            <br />
            <span className="Tile-upperTitle">{languages("LABEL_STATUS")}</span>
            <div className="Tile-upperContent">
              {reception.Status ? (
                languages(`RECEPTIONSTATUS_${reception.Status.toString()}`)
              ) : (
                <br />
              )}
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <span className="Tile-upperTitle">
              {languages("LABEL_OWNERS_REF")}
            </span>
            <div className="Tile-upperContent">
              {reception.OwnersReference ? reception.OwnersReference : "-"}
            </div>
            <br />
            <span className="Tile-upperTitle">
              {languages("LABEL_ORIG_QTY")}
            </span>
            <div className="Tile-upperContent">
              {reception.UnitsQuantity ? reception.UnitsQuantity : "-"}
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <span className="Tile-upperTitle">
              {languages("LABEL_SUPPLIER")}
            </span>
            <div className="Tile-upperContent">
              {reception.Owner ? reception.Owner : "-"}
            </div>
            <br />
            <span className="Tile-upperTitle">
              {languages("LABEL_ORIG_BOXES")}
            </span>
            <div className="Tile-upperContent">
              {reception.BoxesQuantity ? reception.BoxesQuantity : "-"}
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <span className="Tile-upperTitle">
              {languages("LABEL_DATE_OF_RECEIPT")}
            </span>
            <div className="Tile-upperContent">
              {reception.ReceivedDate
                ? moment(reception.ReceivedDate)
                    .format("HH:mm DD.MM.YYYY")
                    .toString()
                : "-"}
            </div>
            <br />
            <span className="Tile-upperTitle">
              {languages("LABEL_CONFIRMED_QTY")}
            </span>
            <div className="Tile-upperContent">
              {reception.UnitsReceived ? reception.UnitsReceived : "-"}
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <span className="Tile-upperTitle">
              {languages("LABEL_CONFIRMED_DATE")}
            </span>
            <div className="Tile-upperContent">
              {reception.ConfirmedDate
                ? moment(reception.ConfirmedDate)
                    .format("HH:mm DD.MM.YYYY")
                    .toString()
                : "-"}
            </div>
            <br />
            <span className="Tile-upperTitle">
              {languages("LABEL_CONFIRMED_PACK_QTY")}
            </span>
            <div className="Tile-upperContent">
              {reception.BoxesReceived ? reception.BoxesReceived : "-"}
            </div>
          </div>
          <Accordion.Title
            className="col-lg-1 col-sm-12 acc-title"
            icon={this.getIcon()}
            active={isCollapseOpen}
            onClick={this.toggleCollapse}
          ></Accordion.Title>
          <Accordion.Content
            className="row col-lg-12"
            active={isCollapseOpen}
            content={
              <ReceptionExtraInfoView
                isEmployee={this.props.isEmployee}
                receptionDetails={this.state.receptionDetails}
              />
            }
          ></Accordion.Content>
          <span className="upper-right-corner">{formattedCreateDate}</span>
        </Accordion>
      </div>
    );
  }
}
