import * as moment from "moment";
import { saveAs } from "file-saver";
import { Object } from "es6-shim";
import { $filter, $timeout } from "ngimport";
import {
  UIRouter,
  StateService,
  Transition,
  TargetState
} from "@uirouter/core";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { ModalUtilities, UtilityFunctions } from "Utilities";
import { UserService, WarehouseService, SamskipNotify } from "Services";
import { $uiRouter, $uibModal } from "Bootstrap/angular";

import "./modal/OrderDetials";

declare var $: any;

function ordersController($scope: any, $transition$: Transition) {
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();

  const currentUser = UserService.getUserProfile();

  /**
   * Initilization
   */
  $scope.itemsSelected = 0;

  // Main warehouse orders data
  $scope.orders = undefined;

  UtilityFunctions.largeSpinner("largeSpinner");

  // Filters Selected
  function initFilter() {
    const Filter: any = {};

    Filter.statusSelection = {
      value: [],
      name: "Status",
      translate: "LABEL_STATUS"
    };
    Filter.CODIV = {
      value: [],
      name: "Company division",
      translate: "LABEL_CODIV"
    };

    return Filter;
  }

  $scope.statusTexts = {
    10: "LABEL_UNSELECTED",
    20: "LABEL_PRINTED",
    35: "LABEL_INPACKING",
    40: "LABEL_PACKCOMPLETE",
    50: "LABEL_WEIGHED",
    70: "LABEL_LOADEDONTRUCK",
    90: "LABEL_SHIPPEDORINVOICED",
    99: "LABEL_CANCELLED"
  };

  $scope.Filter = initFilter();
  $scope.FilterBase = initFilter();

  /*
   *************** Ordering and Filter functions ***************
   */
  $scope.order = {};
  $scope.order.reverse = false;
  $scope.selectedPredicateTranslation = "LABEL_CREATED";
  $scope.order.selectedPredicate = "DateCreated";

  $scope.setPredicate = function setPredicate(pred: string, transl: string) {
    $scope.order.selectedPredicate = pred;
    $scope.selectedPredicateTranslation = transl;
    $scope.order.reverse = false;
    $scope.paginator.changePage($scope.paginator.currentPage, true);
  };

  $scope.$watch("order.reverse", () => {
    // If it's undefined, we know it's initial
    // Then the watch above covers the call
    if ($scope.orders !== undefined) {
      getOrders(1);
    }
  });

  // Removes a single filter
  $scope.removeFilter = function removeFilter(filter: any) {
    for (const key in $scope.Filter) {
      const value = $scope.Filter[key];
      if (filter.name === value.name) {
        initFilter().forEach((initValue: any) => {
          if (initValue.name === value.name) {
            $scope.Filter[key] = initValue;
          }
        });
      }
    }
  };

  // Reset the filter
  $scope.resetAllFilters = function resetAllFilters() {
    $scope.Filter = initFilter();
    $scope.paginator.changePage(1, false);
  };

  /*
   ************* Ordering and Filter functions end **************
   */
  // Headings and ordertypes for warehousedata
  $scope.OrderTypes = [];
  $scope.resultText = "LABEL_PERIOD";
  $scope.dateFrom = "2015-05-01";
  $scope.dateTo = "2015-05-02";
  $scope.CODIV = [];

  if (currentUser) {
    WarehouseService.current(currentUser.User.ID).then((data: any) => {
      data.forEach((value: any) => {
        value.codiv = `${value.CompanyName} - ${value.DivisionName}`;
      });

      $scope.CODIV = data;
    });
  }

  $scope.WarehouseSettings = {
    DateTo: $scope.dateFrom,
    DateFrom: $scope.dateTo
  };

  // In progress state
  if (currentState === "warehouse.orders.inprogress") {
    if ($scope.Filter.statusSelection.value.indexOf(10) === -1) {
      $scope.Filter.statusSelection.value.push(10);
    }
    if ($scope.Filter.statusSelection.value.indexOf(20) === -1) {
      $scope.Filter.statusSelection.value.push(20);
    }
    if ($scope.Filter.statusSelection.value.indexOf(35) === -1) {
      $scope.Filter.statusSelection.value.push(35);
    }
    if ($scope.Filter.statusSelection.value.indexOf(40) === -1) {
      $scope.Filter.statusSelection.value.push(40);
    }
    if ($scope.Filter.statusSelection.value.indexOf(50) === -1) {
      $scope.Filter.statusSelection.value.push(50);
    }
    if ($scope.Filter.statusSelection.value.indexOf(70) === -1) {
      $scope.Filter.statusSelection.value.push(70);
    }
  }

  // Save changes in daterange to session
  function saveDateRange(DateFrom: string, DateTo: string) {
    $scope.WarehouseSettings.DateFrom = DateFrom;
    $scope.WarehouseSettings.DateTo = DateTo;

    UserService.setWebSetting(
      "WarehouseListPage",
      JSON.stringify($scope.WarehouseSettings)
    );
  }

  // Handle diffrent states of warehouse listpage
  function handleState() {
    $scope.dateFrom = currentStateParams.dateFrom || $scope.dateFrom;
    $scope.dateTo = currentStateParams.dateTo || $scope.dateTo;
    saveDateRange($scope.dateFrom, $scope.dateTo);
  }

  handleState();

  // Pagination
  $scope.paginator = {};
  $scope.paginator.currentPage = 1;
  $scope.paginator.maxSize = 10;
  $scope.paginator.totalItems = 0;
  $scope.paginator.itemsPerPage = 20;

  $scope.paginator.changePage = function changePage(
    newPage: number,
    getList?: boolean
  ) {
    const loadListFromRemote = getList === true || typeof getList !== "boolean";

    if (loadListFromRemote === true) {
      getOrders(newPage);
    }
    $scope.paginator.currentPage = newPage;
  };

  // Array with allowed items per page count
  $scope.paginator.itemsPerPageArray = [20, 40, 80, 100];

  // Set how many items per page to display
  $scope.paginator.setItemsPerPage = function setItemsPerPage(items: number) {
    $scope.paginator.itemsPerPage = items;

    // Update settings for user on the server
    UserService.setWebSetting(
      USERWEBSETTINGS.ItemsPerTablePage,
      items.toString()
    );

    // Get data again
    getOrders(1);
  };

  // Get the settings for how many items per table page to display
  const settingsItemsPerTablePage = UserService.getLocalWebSettingValue(
    USERWEBSETTINGS.ItemsPerTablePage
  );
  if (settingsItemsPerTablePage != null && settingsItemsPerTablePage !== "") {
    $scope.paginator.itemsPerPage = settingsItemsPerTablePage;
  } else {
    $scope.paginator.itemsPerPage = 20;
  }

  // populates the list with new values
  function getOrders(page: number) {
    $(".js-DisplayList").fadeTo(200, 0);
    $(".loader").fadeTo(400, 1);

    const handleListData = function handleListData(data: any) {
      $scope.orders = data.items;

      data.items.forEach((it: any) => {
        it.dropdown = [
          {
            icon: "fa fa-download",
            text: "Excel",
            click: `downloadExcel("${it.OrderNumber}")`
          }
        ];
      });

      // Pagination
      $scope.paginator.changePage(data.page, false);
      $scope.paginator.totalItems = data.totalItems;
    };

    WarehouseService.getOrders(
      $scope.dateFrom,
      $scope.dateTo,
      $scope.Filter.CODIV.value.join(),
      $scope.Filter.statusSelection.value.join(),
      $scope.order.selectedPredicate.pred,
      $scope.order.reverse,
      $scope.paginator.itemsPerPage,
      page
    )
      .then((data: any) => {
        handleListData(data);
        $(".loader")
          .clearQueue()
          .fadeTo(400, 0, () => {
            $(".js-DisplayList").fadeTo(500, 1, () => {
              $(".js-ListPage-box").trigger("heightChange");
            });
          });
      })
      .catch((data: any) => {
        if (data) {
          SamskipNotify.displayWarning(data);
        }
        $(".loader")
          .clearQueue()
          .fadeTo(400, 0, () => {
            $(".js-DisplayList").fadeTo(500, 1, () => {
              $(".js-ListPage-box").trigger("heightChange");
            });
          });
      });
  }

  // Toggle status selection in filter
  $scope.toggleStatusSelection = function toggleStatusSelection(
    status: number
  ) {
    $timeout(() => {
      const idx = $scope.Filter.statusSelection.value.indexOf(status);

      // is currently selected
      if (idx > -1) {
        $scope.Filter.statusSelection.value.splice(idx, 1);
      } else {
        $scope.Filter.statusSelection.value.push(status);
      }
      $scope.paginator.changePage(1, true);
    });
  };

  $scope.$watch(
    "Filter.CODIV",
    () => {
      $scope.paginator.changePage(1, true);

      if (Object.is($scope.Filter, $scope.FilterBase)) {
        $(".js-ContentHeader-clearFilters").hide();
      } else {
        $(".js-ContentHeader-clearFilters").show();
      }
    },
    true
  );

  // control size on sidebar
  UtilityFunctions.checkSizes($(".js-Sidebar"), $(".js-ListPage-box"));

  function handleDaterangePickerApplied(ev: any, picker: any) {
    $state.transitionTo(
      currentState,
      {
        dateFrom: picker.startDate.format("YYYY-MM-DD"),
        dateTo: picker.endDate.format("YYYY-MM-DD")
      },
      {
        reload: false
      }
    );

    $scope.dateFrom = picker.startDate.format("YYYY-MM-DD");
    $scope.dateTo = picker.endDate.format("YYYY-MM-DD");
    saveDateRange($scope.dateFrom, $scope.dateTo);

    $scope.paginator.changePage(1, true);
  }

  $("#reportrange").on("apply.daterangepicker", handleDaterangePickerApplied);

  UtilityFunctions.catToggle();
  UtilityFunctions.filterSlideControl(
    "js-Sidebar-triggerFilter",
    "js-Sidebar",
    "LABEL_OPEN_FILTER",
    "LABEL_CLOSE_FILTER"
  );

  /**
   * Downloads order details in Excel
   * If orderNumber is falsy, loop through $scope.orders and gather the
   * order numbers for checked orders.
   * @param  {string}   orderNumber    A single order number OR a falsy value
   */
  $scope.downloadExcel = function downloadExcel(orderNumber: string) {
    const fileName =
      typeof orderNumber === "string"
        ? `Order_${orderNumber}_details`
        : `OrderDetails_${moment().format("YYYY-MM-DD")}`;
    const orderNumbers: any = [];

    if (!orderNumber) {
      // The excel button in the selected bar (for mulptiple orders) clicked
      $scope.orders.forEach((it: any) => {
        if (it.checked) {
          orderNumbers.push(it.OrderNumber);
        }
      });
    }
    WarehouseService.downloadOrderDetailsExcel(orderNumbers).then(
      (data: any) => {
        const out = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(out, `${fileName}.xlsx`);
      }
    );
  };

  /**
   *  functionality -- Shows information for a single order
   *  @param  {obj}    order A order object describing a single order
   **********************************************************************/
  $scope.getOrderDetails = function getOrderDetails(orderNumber: string) {
    ModalUtilities.showExtraLargeModal("orderDetail", {
      order: function order() {
        return orderNumber;
      }
    });
  };

  $scope.itemChecked = function itemChecked(checked: boolean) {
    if (checked) {
      $scope.itemsSelected += 1;
    } else {
      $scope.itemsSelected -= 1;
    }
  };

  /**
   * Check all orders in the orders-list and display the multi-selection bar
   */
  $scope.allSelected = function allSelected() {
    let checked = 0;
    $scope.filteredOrders.forEach((it: any) => {
      it.checked = true;
      checked += 1;
    });
    $scope.itemsSelected = checked;
  };

  /**
   * Uncheck all orders in the orders-list and hide the multi-selection bar
   */
  $scope.noneSelected = function noneSelected() {
    $scope.filteredOrders.forEach((it: any) => {
      it.checked = false;
    });
    $scope.itemsSelected = 0;
  };
}

ordersController.$inject = ["$scope", "$transition$"];
export default ordersController;
