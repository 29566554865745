import * as angular from "angular";
import { saveAs } from "file-saver";
import { $filter } from "ngimport";

import { WarehouseService } from "Services";
import { UtilityFunctions } from "Utilities";

import * as modalTemplate from "./orderDetailsModal.html";

const OrderDetials: ng.IComponentOptions = {
  template: `${modalTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<"
  },
  controller: class OrderDetials {
    private dismiss: Function;
    private resolve: any;

    order = this.resolve.order;
    orderDetails = [];
    selectedPredicate = "ProductNumber";
    orderBy = $filter("orderBy");
    reverse = false;

    OrderTypes = [
      {
        pred: "ProductNumber",
        name: "ProductNumber",
        translate: "ProductNumber"
      },
      { pred: "Description", name: "Description", translate: "Description" },
      {
        pred: "VendorItemNumber",
        name: "VendorItemNumber",
        translate: "VendorItemNumber"
      },
      {
        pred: "VendorDescription",
        name: "VendorDescription",
        translate: "VendorDescription"
      },
      { pred: "BoxQuantity", name: "BoxQuantity", translate: "BoxQuantity" },
      { pred: "OrderUnits", name: "OrderUnits", translate: "OrderUnits" },
      {
        pred: "DeliveredUnits",
        name: "DeliveredUnits",
        translate: "DeliveredUnits"
      }
    ];

    constructor() {
      WarehouseService.getOrderDetails(this.order.OrderNumber).then(
        (data: any) => {
          this.orderDetails = data;
          this.sort();
        }
      );
    }

    sort() {
      this.orderDetails = this.orderBy(
        this.orderDetails,
        this.selectedPredicate,
        this.reverse
      );
    }

    downloadExcel(orderNumber: string[]) {
      WarehouseService.downloadOrderDetailsExcel(orderNumber).then(
        (data: any) => {
          saveAs(
            new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }),
            `Order_${orderNumber}_details.xlsx`
          );
        }
      );
    }

    setPredicate(pred: string) {
      if (this.selectedPredicate === pred) {
        this.reverse = !this.reverse;
      } else {
        this.selectedPredicate = pred;
      }

      this.sort();
    }

    cancel() {
      this.dismiss();
    }
  }
};

angular.module("serviceWebApp").component("orderDetail", OrderDetials);
