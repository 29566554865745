import * as angular from "angular";
import { $uiRouter } from "Bootstrap/angular";
import StockPage from "./stock";
import ReceptionPage from "./receptions";

// Controllers
import WarehouseController from "Warehouse/WarehouseController";
import OrdersController from "Warehouse/OrdersController";

// Templates
import * as defaultTemplate from "Warehouse/partials/default.html";
import * as warehouseTemplate from "Warehouse/partials/warehouse.html";
import * as ordersTemplate from "Warehouse/partials/orders.html";
import * as receptionsTemplate from "Warehouse/partials/receptions.html";

// Constants
import { USER_ROLES } from "Constants/UserRoles";

export const MAIN_STATE: string = "warehouse";

class Warehouse {
  constructor() {
    const routes: any[] = [
      // Abstract state (will never be routed to)
      {
        name: `${MAIN_STATE}`,
        url: `/${MAIN_STATE}`,
        parent: "masterPage",
        template: `${defaultTemplate}`,
        controller: WarehouseController,
        controllerAs: "$ctrl",
        data: { roles: [USER_ROLES.WHOUSE] },
        abstract: true
      },
      // Default state
      {
        name: `${MAIN_STATE}.default`,
        url: "",
        template: `${warehouseTemplate}`,
        controller: WarehouseController,
        controllerAs: "$ctrl",
        data: { roles: [USER_ROLES.WHOUSE] }
      },
      {
        name: `${MAIN_STATE}.orders`,
        url: "/orders",
        template: `${ordersTemplate}`,
        controller: OrdersController,
        controllerAs: "$ctrl",
        data: { roles: [USER_ROLES.WHOUSE] }
      },
      {
        name: `${MAIN_STATE}.orders.inprogress`,
        url: "/inprogress/:dateFrom/:dateTo",
        template: `${ordersTemplate}`,
        controller: OrdersController,
        controllerAs: "$ctrl",
        data: { roles: [USER_ROLES.WHOUSE] }
      },
      {
        name: `${MAIN_STATE}_stock`,
        url: `/${MAIN_STATE}/stock`,
        parent: "masterPage",
        component: StockPage,
        data: { roles: [USER_ROLES.WHOUSE] }
      },
      {
        name: `${MAIN_STATE}_receptions`,
        url: `/${MAIN_STATE}/receptions`,
        parent: "masterPage",
        component: ReceptionPage,
        data: { roles: [USER_ROLES.WHOUSE] }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

angular.module("serviceWebApp").run(Warehouse);
