import React from "react";

import {
  Sidebar,
  Button,
  Form,
  Checkbox,
  DropdownItemProps
} from "semantic-ui-react";

import { Collapse, Input } from "Component";

import DatePicker from "react-datepicker";

import { ReceptionFilters, ReceptionStatus } from "./interfaces";

import styles from "./sidebar.less";

import { ArrayUtilities } from "Utilities";

import { languages, WarehouseService } from "Services";

export default class ReceptionSidebar extends React.Component<
  {
    visible: boolean;
    onClose: Function;
    filters: ReceptionFilters;
    onFilter: Function;
    disabled: boolean;
    selectedCompany: Company;
    isEmployee: boolean;
    onSearch: Function;
    departments: DropdownItemProps[];
    receptionTypes: DropdownItemProps[];
    clearFilters: Function;
  },
  {
    receptionStatuses: ReceptionStatus[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      receptionStatuses: []
    };
  }

  async componentDidMount() {
    const receptionStatuses = await WarehouseService.getReceptionStatuses();

    this.setState({
      receptionStatuses
    });
  }
  render() {
    const {
      onClose,
      onFilter,
      filters,
      disabled,
      selectedCompany,
      onSearch,
      clearFilters
    } = this.props;
    return (
      <Sidebar
        className={`js-Sidebar ${styles.filter}`}
        animation="overlay"
        icon="labeled"
        direction="right"
        visible={this.props.visible}
      >
        <Form>
          <Button
            type="button"
            onClick={() => onClose()}
            icon="chevron right"
          />
          <Button
            type="button"
            onClick={() => clearFilters()}
            disabled={disabled}
          >
            {languages("LABEL_CLEAR")}
          </Button>
          <Button
            onClick={() => onSearch()}
            icon="search"
            floated="right"
            disabled={disabled}
            loading={disabled}
          />
          <Collapse title={languages("FILTER_SEARCH")} open={true}>
            <Form.Group>
              <Form.Field>
                <Input
                  disabled={disabled}
                  type="text"
                  value={filters.searchString.value}
                  onChange={(n: string) =>
                    onFilter(
                      "searchString",
                      "FILTER_SEARCH",
                      n
                    )
                  }
                  onBlur={() => {
                    onFilter(
                      "searchString",
                      "FILTER_SEARCH",
                      filters.searchString.value
                    );
                  }}
                ></Input>
              </Form.Field>
              <Form.Field>
                <Checkbox
                  className="first"
                  label={languages("LABEL_RECEPTION_ID")}
                  disabled={disabled}
                  checked={
                    filters.searchTypes.value.indexOf("ReceptionID") > -1
                  }
                  value={"ReceptionID"}
                  onChange={(e: any, d: any) => {
                    onFilter(
                      "searchTypes",
                      "",
                      ArrayUtilities.toggleValueInArray(
                        filters.searchTypes.value,
                        d.value
                      )
                    );
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label={languages("LABEL_OWNERS_REF")}
                  disabled={disabled}
                  checked={
                    filters.searchTypes.value.indexOf("OwnersReference") > -1
                  }
                  value={"OwnersReference"}
                  onChange={(e: any, d: any) =>
                    onFilter(
                      "searchTypes",
                      "",
                      ArrayUtilities.toggleValueInArray(
                        filters.searchTypes.value,
                        d.value
                      )
                    )
                  }
                />
              </Form.Field>
            </Form.Group>
          </Collapse>
          <Collapse title={languages("FILTER_DATERANGE")} open={true}>
            <DatePicker
              className="ui input"
              dateFormat="dd/MM/yyyy HH:mm"
              peekNextMonth
              showWeekNumbers
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              disabled={disabled}
              selected={filters.dateFrom.value}
              onChange={(date: Date) => {
                if (date.getTime() > filters.dateTo.value.getTime()) {
                  onFilter(
                    "dateTo",
                    "",
                    date
                  );
                }
                onFilter(
                  "dateFrom",
                  "",
                  date
                );
              }}
              popperModifiers={{
                preventOverflow: {
                  enabled: true
                }
              }}
              selectsStart
            />
            <DatePicker
              className="ui input"
              peekNextMonth
              showWeekNumbers
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              disabled={disabled}
              selected={filters.dateTo.value}
              minDate={filters.dateFrom.value}
              onChange={date =>
                onFilter(
                  "dateTo",
                  "",
                  date
                )
              }
              popperModifiers={{
                preventOverflow: {
                  enabled: true
                }
              }}
              selectsEnd
            />
          </Collapse>
          <Collapse
            title={languages("FILTER_STATUS")}
            open={true}
            className="margin-top-55"
          >
            <Form.Group inline={false}>
              {this.state.receptionStatuses.map(data => {
                return (
                  <Form.Field
                    key={data.Code}
                    disabled={disabled}
                    name="status"
                    control={Checkbox}
                    checked={ArrayUtilities.includes(
                      filters.receptionStatuses.value,
                      data.Code
                    )}
                    value={data.Code}
                    label={languages(`RECEPTIONSTATUS_${data.Code.toString()}`)}
                    onChange={(e: any, d: any) =>
                      onFilter(
                        "receptionStatuses",
                        "FILTER_STATUS",
                        ArrayUtilities.toggleValueInArray(
                          filters.receptionStatuses.value,
                          d.value
                        )
                      )
                    }
                  ></Form.Field>
                );
              })}
            </Form.Group>
          </Collapse>
          <Collapse title={languages("FILTER_RESTRICT")} open={true}>
            <Form.Group>
              <Form.Field
                disabled={disabled}
                name="status"
                control={Checkbox}
                checked={filters.quantityDiscrepancy.value}
                label={languages("FILTER_WITH_PACKAGE_VARIANCE")}
                onChange={(e: any, d: any) =>
                  onFilter(
                    "quantityDiscrepancy",
                    "FILTER_WITH_PACKAGE_VARIANCE",
                    d.checked
                  )
                }
              ></Form.Field>
            </Form.Group>
          </Collapse>
        </Form>
      </Sidebar>
    );
  }
}
