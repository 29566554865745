import * as React from "react";
import * as ReactDOM from "react-dom";

import { ReceptionDetailListItem } from "./interfaces";

import styles from "./extraInfoView.less";

import { languages } from "Services";

declare var GlobalConfig: GlobalConfig;

export default class ReceptionExtraInfoView extends React.Component<
  {
    isEmployee: boolean;
    receptionDetails?: ReceptionDetailListItem[];
  },
  {}
> {
  constructor(props: any) {
    super(props);
  }
  render() {
    const { receptionDetails } = this.props;
    return (
      <div className={`${styles.wrapper}`}>
        <div className="inner-wrapper">
          {receptionDetails
            ? receptionDetails.map((detail: ReceptionDetailListItem) => {
                return (
                  <div
                    key={detail.ID}
                    className={`js-Tile ${styles.view} ${
                      detail.Variance !== 0 ? "highlight" : ""
                    }`}
                  >
                    <div className="col-sm-2">
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_RECEPTION_ID")}
                        </div>
                        <div className="lower-info-string">
                          {detail.ID ? detail.ID : "-"}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_ITEM_PACK_QUANTITY")}
                        </div>
                        <div className="lower-info-string">
                          {detail.Quantity ? detail.Quantity : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_PICK_LOCN")}
                        </div>
                        <div className="lower-info-string">
                          {detail.Location ? detail.Location : "-"}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_ASN_PACK_QUANTITY")}
                        </div>
                        <div className="lower-info-string">
                          {detail.ShippedCasesQuantity
                            ? detail.ShippedCasesQuantity
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_SKU_BARCODE")}
                        </div>
                        <div className="lower-info-string">
                          {detail.Barcode ? detail.Barcode : "-"}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_ORIG_QTY")}
                        </div>
                        <div className="lower-info-string">
                          {detail.ShippedQuantity
                            ? detail.ShippedQuantity
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_DESCRIPTION")}
                        </div>
                        <div className="lower-info-string">
                          {detail.Description ? detail.Description : "-"}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_CONFIRMED_QTY")}
                        </div>
                        <div className="lower-info-string">
                          {detail.ReceivedQuantity
                            ? detail.ReceivedQuantity
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_SUPPLIERS_DESCRIPTION")}
                        </div>
                        <div className="lower-info-string">
                          {detail.CustomersDescription
                            ? detail.CustomersDescription
                            : "-"}
                        </div>
                      </div>
                      <br />
                      <div>
                        <div className="lower-info-title">
                          {languages("LABEL_VARIANCE")}
                        </div>
                        <div className="lower-info-string">
                          {detail.Variance !== null ? detail.Variance : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}
