import * as angular from "angular";
import latinize from "latinize";

// unique
export function uniqueFilter() {
  return function uniqueFilter(arr: any[], field: string): any[] {
    const o: any = {};
    let i;
    const l = arr.length;
    const r: any = [];
    for (i = 0; i < l; i += 1) {
      o[arr[i][field]] = arr[i];
    }
    // TODO: Fix this linting error!
    Object.keys(o).forEach(it => {
      r.push(o[it][field]);
    });
    return r;
  };
}

// uniqueValue
export function uniqueValueFilter() {
  return function uniqueValueFilter(arr: any[]): any[] {
    const o: any = {};
    let i;
    const l = arr.length;
    const r: any = [];
    for (i = 0; i < l; i += 1) {
      o[arr[i]] = arr[i];
    }
    // TODO: Fix this linting error!
    Object.keys(o).forEach(it => {
      r.push(o[it]);
    });
    return r;
  };
}

// thousand
// Thousand group filter
export function thousandFilter() {
  return function thousandFilter(field: any, currency: any): string {
    if (field !== 0 && !field) return "";

    let transformed;

    // Default thousand seperator is comma
    let sep = ",";

    // Special condition for Iceland
    if (currency === "ISK") {
      sep = ".";
    }

    // If we find the seperator, only take numbers to infront of it
    transformed =
      field.toString().indexOf(sep) > -1
        ? field.toString().substring(0, field.toString().indexOf(sep))
        : field.toString();

    // Format to thousand with appropriate seperator
    return transformed.replace(/\B(?=(\d{3})+(?!\d))/g, sep);
  };
}

// startFrom
// Start from filter (used for pagination)
export function startFromFilter() {
  return function startFromFilter(input: any[], start: number): any[] {
    const positiveStart = +start;
    if (!input) {
      return input;
    }
    return input.slice(positiveStart);
  };
}

// nullFix
// Fix null displaying in chosen selector. returns (value, label)
export function nullFixFilter() {
  return function nullFixFilter(arr: any[], replaceText: string): any {
    const array: any = {};
    angular.forEach(arr, value => {
      if (value == null) {
        array[replaceText] = "0";
      } else {
        array[value] = value;
      }
    });
    return array;
  };
}

export function simpleSort() {
  return function simpleSort(inputArr: any[]): any[] {
    return sortArray(inputArr);
  };
}

export function simpleSortDesc() {
  return function simpleSortDesc(inputArr: any[]): any[] {
    return sortArray(inputArr).reverse();
  };
}

/**
 * Custom filter for string values.
 * @return {function} Returns a filter factory function
 */
export function stringContainsFilter() {
  /**
   * The filter checks for matching values in each string in the
   * input array.
   * @param  {string[]} inputArr    An array of strings
   * @param  {string} searchValue Value to look for matches for
   * @return {string[]}             Returns a new array containing strings
   *     where the searchValue occurs at least once somewhere in each string.
   */
  return function stringContainsFilter(inputArr: any[], searchValue: string) {
    if (!inputArr || inputArr.length < 2) {
      return inputArr;
    }
    const matchInput = new RegExp(searchValue, "i");
    return inputArr.filter(it => {
      return !!it && matchInput.test(it);
    });
  };
}

export function customNumberFilter() {
  // Defaults are dots(.) to separate thousands and commas for
  // decimal-parts (the sensible way)
  const defaults = {
    DECIMAL_SEP: ",",
    THOUSAND_SEP: "."
  };

  const decorators: any = {
    kg: function kg(input: number) {
      return `${input} kg`;
    },
    volume: function volume(input: number) {
      return `${input} m\u00B3`;
    },
    celcius: function celcius(input: number) {
      return `${input} \u00B0C`;
    }
  };

  /**
   * Custom filter for numeric fields that manipulate the formatting based on
   * the passed in types and the user's preferred language
   * @param  {string} input       Numeric value to be manipulated
   * @param  {string} type        Identifier (key) for decorator types defined by the filter
   * @param  {bool}   decorate    Specifies weather or not the decorator should
   *                              be shown or not, defaults to false if not specified
   * @return {string}             Manipulated representation of the input string.
   */
  return function customNumberFilter(
    input: string,
    type: string,
    decorate: string
  ) {
    let num;
    let output;
    let intPart;
    let decimalPart;

    if (input === null || input === undefined) {
      return "";
    }

    if (angular.isString(input)) {
      /*
       * TODO: Parse string into number if it's on some form of
       * 1,123,123.0 OR 123,2 OR 1 123 123.99
       */
      num = Number(input);
    } else {
      num = Number(input);
    }

    if (!(angular.isNumber(num) && isFinite(num))) {
      return "";
    }

    const intDecimalSplit = num.toString().split(".");

    if (intDecimalSplit[0]) {
      intPart = intDecimalSplit[0].replace(
        /\B(?=(\d{3})+(?!\d))/g,
        defaults.THOUSAND_SEP
      );
    }
    if (intDecimalSplit[1]) {
      decimalPart = intDecimalSplit[1].substring(0, 2);
    }

    output = decimalPart
      ? [intPart, decimalPart].join(defaults.DECIMAL_SEP)
      : intPart;

    if (type && decorators[type] && decorate) {
      output = decorators[type](output);
    }
    return output;
  };
}

/**
 * Sorts parameter array
 * @param  {arr[]} inputArr    Array of strings or numbers
 */
function sortArray(inputArr: any[]): any[] {
  const allString = inputArr.every(it => {
    return typeof it === "string";
  });

  if (allString) {
    return inputArr.sort((left, right) => {
      return left.localeCompare(right);
    });
  }
  return inputArr.sort();
}

/**
 * Custom filter for uibTypeahead so that it also supports match highlighting when searching without icelandic characters
 * @return {function} Returns a filter factory function
 */
export function latinizedTypeaheadHighlightFilter() {
  /**
   * The filter checks for matching values in a matched string and returns string with the matched substring highlighted.
   * @param  {string} match     A matched string
   * @param  {string} query     Query value to highlight in the matched string.
   * @return {string}           Returns the matched string with the query highlighted in a <strong> tag
   */
  return function(match: string, query: string) {
    if (!query) {
      return match;
    }
    const start = latinize(match.toLowerCase()).indexOf(
      latinize(query.toLowerCase())
    );
    if (start < 0) {
      return match;
    }
    return `${match.substring(0, start)}<strong>${match.substr(
      start,
      query.length
    )}</strong>${match.substr(start + query.length)}`;
  };
}

export const CreateFilterValue = <T>(
  type: string,
  value: T
): FilterValue<T> => {
  return {
    type,
    value,
    changed: false
  } as FilterValue<T>;
};
