import * as React from "react";
import * as _ from "lodash";
import * as moment from "moment";

import {
  ReceptionDetailListItem,
  ReceptionFilters,
  ReceptionListItem,
  ReceptionPageState
} from "./interfaces";
import {
  Accordion,
  Table,
  Sidebar,
  Segment,
  Dropdown,
  DropdownItemProps,
  Button,
  Loader,
  Icon
} from "semantic-ui-react";

import ReceptionSidebar from "./sidebar";
import ReceptionView from "./view";

import styles from "./index.less";
import { Col, PageHeader, Row } from "react-bootstrap";
import { languages } from "Services/TranslationService";
import { UserService, WarehouseService, SamskipNotify } from "Services";
import {
  Download,
  ItemsPerPage,
  PageNumbers,
  Pager,
  Pagination,
  Header
} from "Component";
import GeneralLoader from "Utilities/GeneralLoader";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { reaction } from "mobx";
import TranslationStore from "Stores/TranslationStore";
import { CreateFilterValue } from "Filters/filters";
import { FILTER_TYPES } from "Constants/FilterConstants";
import { receptionWithPackageVariance } from "./filters";

const isEmployee = UserService.isEmployee();

const InitialState: ReceptionPageState = {
  isEmployee,
  selectedCompany: UserService.getSelectedCompany(),
  receptions: [],
  filteredReceptions: [],
  sidebarVisible: false,
  filters: {
    partner: CreateFilterValue<string>(FILTER_TYPES.SERVER, ""),
    searchString: CreateFilterValue<string>(FILTER_TYPES.SERVER, ""),
    searchTypes: CreateFilterValue<string[]>(FILTER_TYPES.SERVER, []),
    dateFrom: CreateFilterValue<Date>(
      FILTER_TYPES.SERVER,
      new Date("May 01 2021")
    ),
    dateTo: CreateFilterValue<Date>(FILTER_TYPES.SERVER, new Date()),
    receptionStatuses: CreateFilterValue<number[]>(FILTER_TYPES.SERVER, []),
    quantityDiscrepancy: CreateFilterValue<boolean>(FILTER_TYPES.CLIENT, false)
  },
  receptionsPerPage: 10,
  currentPage: 1,
  isLoading: true,
  sortOptions: [
    {
      element: "LABEL_RECEPTION_IDS_FROM",
      value: "ReceptionID",
      text: ""
    },
    {
      element: "LABEL_STATUS_FROM",
      value: "Status",
      text: ""
    },
    {
      element: "LABEL_CREATE_DATE_FROM",
      value: "CreatedDate",
      text: ""
    }
  ],
  sorting: {
    sortBy: "ReceptionID",
    isDesc: false
  },
  labels: [],
  receptionTypeOptions: [],
  departmentOptions: []
};

export default class ReceptionPage extends React.Component<
  {},
  ReceptionPageState
> {
  constructor(props: any) {
    super(props);
    this.state = _.cloneDeep(InitialState);
  }

  async componentDidMount() {
    const selectedCompany = UserService.getSelectedCompany();
    reaction(
      () => TranslationStore.getSelectedLanguage(),
      async () => {
        await this.createDropdownOptions();
      }
    );

    await this.createDropdownOptions();

    const WebSettingsFiltersJSON = UserService.getLocalWebSettingValue(
      USERWEBSETTINGS.ReceptionListPage
    );

    if (WebSettingsFiltersJSON) {
      const WebSettingsFilters: {
        DateFrom: any;
        DateTo: any;
        ReceptionsPerPage: any;
      } = JSON.parse(WebSettingsFiltersJSON);
      const filters = this.state.filters;
      filters.dateFrom.value = WebSettingsFilters.DateFrom
        ? new Date(WebSettingsFilters.DateFrom)
        : new Date();
      filters.dateTo.value = WebSettingsFilters.DateTo
        ? new Date(WebSettingsFilters.DateTo)
        : new Date();

      const mDateFrom = moment(filters.dateFrom.value);
      const mDateTo = moment(filters.dateTo.value);

      if (mDateTo.diff(mDateFrom, "months", true) > 3) {
        filters.dateFrom.value = new Date(
          mDateTo.subtract(3, "months").format("YYYY-MM-DD")
        );
      }

      const receptionsPerPage =
        WebSettingsFilters.ReceptionsPerPage || this.state.receptionsPerPage;
      this.setState({ filters, receptionsPerPage, selectedCompany }, () =>
        this.onFilter(
          "partner",
          "LABEL_CUSTOMERGROUP",
          selectedCompany.PartnerCode
        )
      );
    } else {
      this.setState({ selectedCompany }, () =>
        this.onFilter(
          "partner",
          "LABEL_CUSTOMERGROUP",
          selectedCompany.PartnerCode
        )
      );
    }
    this.getReceptions();
  }

  onPageChange = (value: number) => {
    this.setState({ currentPage: value });
  };

  closeSidebar = () => this.setState({ sidebarVisible: false });
  openSidebar = () => this.setState({ sidebarVisible: true });

  clearFilters = (): void => {
    const keys = Object.keys(this.state.filters);
    const filterArr: any[] = [];
    keys.forEach(key => {
      if (
        key !== "dateFrom" &&
        key !== "dateTo"
      ) {
        filterArr.push(key);
        this.state.filters[key] = InitialState.filters[key];
      }
    });
    this.setState(
      {
        filters: this.state.filters,
        labels: this.state.labels.filter(item => {
          return filterArr.indexOf(item.key) < 0;
        })
      },
      this.getReceptions
    );
  };

  resetFiltersChanged = (): void => {
    const filters = _.clone(this.state.filters);
    const keys = Object.keys(filters);
    keys.forEach(key => {
      filters[key].changed = false;
    });
    this.setState({ filters });
  };

  createDropdownOptions = async () => {
    const tmpSortOptions = _.cloneDeep(InitialState.sortOptions);
    const sortOptions = tmpSortOptions.map(item => this.translateText(item));

    this.setState({ sortOptions });
  };

  translateText = (item: any): any => {
    return { ...item, text: languages(item.element) };
  };

  labelExists = (label: string): boolean => {
    return this.state.labels.some(item => item.content === label);
  };

  addLabel = (key: string, label: string): void => {
    this.state.labels.push({ key, content: label });
    this.setState({ labels: this.state.labels });
  };

  removeLabel = (key: string): void => {
    const newArr = this.state.labels.filter(item => {
      return item.key !== key;
    });
    this.setState({ labels: newArr });
  };

  removeFilter = (key: string): void => {
    this.removeLabel(key);
    this.state.filters[key] = InitialState.filters[key];
    this.setState({ filters: this.state.filters }, this.getReceptions);
  };

  getReceptions = async (): Promise<any> => {
    const { filters, receptionsPerPage, currentPage, sorting } = this.state;
    this.setState({ isLoading: true });
    GeneralLoader.increase();
    WarehouseService.getReceptions(
      filters.dateFrom.value,
      filters.dateTo.value,
      filters.searchString.value,
      filters.searchTypes.value,
      filters.receptionStatuses.value,
      sorting.sortBy,
      sorting.isDesc
    )
      .then((receptions: ReceptionListItem[]) => {
        this.setState({ receptions, isLoading: false });
        this.clientFilters();
        this.resetFiltersChanged();
        GeneralLoader.decrease();
      })
      .catch((err: string) => {
        this.setState({ isLoading: false });
        SamskipNotify.displayError(languages(err));
        GeneralLoader.decrease();
      });
  };

  saveWebSettings = () => {
    UserService.setWebSetting(
      USERWEBSETTINGS.ReceptionListPage,
      JSON.stringify({
        DateFrom: this.state.filters.dateFrom.value,
        DateTo: this.state.filters.dateTo.value,
        ReceptionsPerPage: this.state.receptionsPerPage
      })
    );
  };

  setReceptionsPerPage = (value: number) => {
    this.setState({ receptionsPerPage: value, currentPage: 1 }, () => {
      this.saveWebSettings();
    });
  };

  onFilter = async (key: string, label: string, value: any) => {
    this.state.filters[key].value = value;
    this.state.filters[key].changed = true;
    if (
      !this.labelExists(label) &&
      ((!Array.isArray(value) && value) || value.length) &&
      label
    ) {
      this.addLabel(key, label);
    } else if (
      this.labelExists(label) &&
      (!value || (Array.isArray(value) && !value.length))
    ) {
      this.removeLabel(key);
    }

    const WebSettingsFilterKeys = [
      "dateFrom",
      "dateTo"
    ];

    this.setState(
      {
        filters: this.state.filters
      },
      () => {
        if (WebSettingsFilterKeys.indexOf(key) > -1) {
          this.saveWebSettings();
        }
      }
    );
  };

  clientFilters = () => {
    const { filters } = this.state;
    let receptions = _.clone(this.state.receptions);
    if (filters.quantityDiscrepancy.value) {
      receptions = receptions.filter(receptionWithPackageVariance);
    }
    this.setState({ filteredReceptions: receptions, currentPage: 1 });
  };

  onSearch = () => {
    const keys = Object.keys(this.state.filters);
    const serverFiltersChanged = keys.filter(
      k =>
        this.state.filters[k].type === FILTER_TYPES.SERVER &&
        this.state.filters[k].changed
    );

    // Only refetch receptions if server filters have changed
    if (serverFiltersChanged.length > 0) {
      this.getReceptions();
    } else {
      this.clientFilters();
    }
    this.resetFiltersChanged();
  };

  onSorting = (key: string, value: any) => {
    this.state.sorting[key] = value;

    this.setState(
      {
        sorting: this.state.sorting,
        isLoading: true
      },
      this.getReceptions
    );
  };

  onExcel = async () => {
    const res = await WarehouseService.getExcelForReceptions(
      this.state.receptions
    );
    return res;
  };

  render() {
    const {
      isLoading,
      sidebarVisible,
      filters,
      selectedCompany,
      labels,
      receptionsPerPage,
      currentPage,
      receptions,
      filteredReceptions,
      isEmployee,
      sortOptions,
      sorting
    } = this.state;

    const indexOfLastReception = currentPage * receptionsPerPage;
    const indexOfFirstReception = indexOfLastReception - receptionsPerPage;
    const currentPosts = filteredReceptions.slice(
      indexOfFirstReception,
      indexOfLastReception
    );

    return (
      <Sidebar.Pushable
        as={Segment.Group}
        className={`${styles.page} h-100 ListPage`}
      >
        <ReceptionSidebar
          disabled={isLoading}
          visible={sidebarVisible}
          onClose={this.closeSidebar}
          filters={filters}
          onFilter={this.onFilter}
          selectedCompany={selectedCompany}
          isEmployee={this.state.isEmployee}
          onSearch={this.onSearch}
          receptionTypes={this.state.receptionTypeOptions}
          departments={this.state.departmentOptions}
          clearFilters={this.clearFilters}
        />
        <Sidebar.Pusher
          className={`col-xs-12 js-ListPage-box ${
            sidebarVisible ? "js-ListPage-box-enlarge" : ""
          }`}
        >
          <div className={`ListPage-content ${styles.content}`}>
            <Header
              site="reception"
              count={filteredReceptions.length}
              openSideBar={this.openSidebar}
              labels={labels}
              removeFilter={this.removeFilter}
              onExcel={this.onExcel}
            />
            <div className="js-DisplayList u-posRelative">
              <ItemsPerPage
                items={[10, 25, 50, 100]}
                value={receptionsPerPage}
                onValue={this.setReceptionsPerPage}
                disabled={isLoading}
              ></ItemsPerPage>
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <span>
                    {`${languages("LABEL_SORTBY")} `}
                    <Dropdown
                      inline
                      options={sortOptions}
                      disabled={isLoading}
                      defaultValue={this.state.sorting.sortBy}
                      onChange={(e, d) => this.onSorting("sortBy", d.value)}
                    />
                    <Button
                      icon
                      compact
                      size="massive"
                      basic
                      disabled={isLoading}
                      onClick={() => this.onSorting("isDesc", !sorting.isDesc)}
                    >
                      {sorting.isDesc ? (
                        <Icon name="sort alphabet down" />
                      ) : (
                        <Icon name="sort alphabet up" />
                      )}
                    </Button>
                  </span>
                </div>
                <div className="col-sm-6 col-md-3"></div>
              </div>
              <div className="js-Tile-wrapper">
                {currentPosts
                  ? currentPosts.map(
                      (reception: ReceptionListItem, i: number) => {
                        return (
                          <ReceptionView
                            isEmployee={isEmployee}
                            reception={reception}
                            key={reception.ReceptionID}
                          />
                        );
                      }
                    )
                  : null}
                {filteredReceptions &&
                  filteredReceptions.length == 0 &&
                  !this.state.isLoading && (
                    <div className="ListPage-noResults">
                      {languages("TEXT_NORESULTS")}
                    </div>
                  )}
                <Loader active={isLoading} size="big" />
              </div>
              <div className="DisplayList-paginator col-sm-12 col-lg-12">
                {filteredReceptions.length > 0 && (
                  <Pagination
                    page={currentPage}
                    totalPages={Math.ceil(
                      filteredReceptions.length / this.state.receptionsPerPage
                    )}
                    onPageChange={this.onPageChange}
                    disabled={isLoading}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
