import * as React from "react";
import * as _ from "lodash";
import * as moment from "moment";
import { Row, Col, PageHeader } from "react-bootstrap";
import {
  Accordion,
  Table,
  Sidebar,
  Segment,
  Dropdown,
  DropdownItemProps,
  Button
} from "semantic-ui-react";
import TranslationService, { languages } from "Services/TranslationService";
import {
  StockPageState,
  StockListItem,
  StockDetailListItem,
  StockPageFilter
} from "./interfaces";
import { UserService, WarehouseService, SamskipNotify } from "Services";
import { Download, Spinner, Pager, PageNumbers } from "../../../components";
import Labels, { onAdd } from "../../../components/Labels";
import base64url from "base64url";
import styles from "./index.less";
import "semantic-ui-less/semantic.less";
import "ts-nameof";
import { reaction } from "mobx";
import GeneralLoader from "Utilities/GeneralLoader";

export default class StockPage extends React.Component<{}, StockPageState> {
  state: StockPageState = {
    activeIndex: -1,
    pageCurrent: 1,
    pageLength: 10,
    stockList: [],
    stockDetailList: [],
    isLoading: true,
    sidebarVisible: false,
    sidebarOptions: {
      customerItemNo: [],
      houseNo: [],
      externalBatchNo: [],
      customerNo: []
    },
    labels: [],
    stockListItemFilters: [
      { values: [], key: "CustomerNo" },
      {
        values: [],
        key: "ExternalCustomerItemNo"
      }
    ],
    stockListDetailItemFilters: [
      { values: [], key: "HouseNo" },
      {
        values: [],
        key: "ExternalBatchNo"
      }
    ]
  };

  componentWillMount = () => {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({})
    );
  };

  async componentDidMount() {
    const selectedCompany = UserService.getSelectedCompany();
    GeneralLoader.increase();
    const list = await WarehouseService.getStockList(
      selectedCompany.SapID
    ).finally(() => {
      GeneralLoader.decrease();
    });

    const promises: Promise<any>[] = [];
    list.map((item: StockListItem) => {
      const p = WarehouseService.getStockDetailList(
        selectedCompany.SapID,
        base64url.encode(item.CustomerItemNo)
      ).then(detail => {
        item.StockDetailList = detail;
      });
      promises.push(p);
    });
    Promise.all(promises).then(() => {
      this.setState({ isLoading: false, stockList: list });
      const filterOptions: StockPageFilter = {
        customerItemNo: this.createOptions(
          list,
          "ExternalCustomerItemNo"
        ),
        houseNo: this.createOptionsDetails(
          list,
          "HouseNo"
        ),
        externalBatchNo: this.createOptionsDetails(
          list,
          "ExternalBatchNo"
        ),
        customerNo: this.createOptions(
          list,
          "CustomerNo"
        )
      };
      this.setState({ sidebarOptions: filterOptions });
    });
    this.catToggle();
  }

  closeSidebar = () => this.setState({ sidebarVisible: false });
  openSidebar = () => this.setState({ sidebarVisible: true });

  filterStockListItems = (list: StockListItem[]): StockListItem[] => {
    const { stockListItemFilters, stockListDetailItemFilters } = this.state;

    const stockListFiltered = list.filter((item: StockListItem) => {
      let result = true;
      for (const filter of stockListItemFilters) {
        if (filter.values.length === 0) {
          continue;
        }
        if (
          item[filter.key] === undefined ||
          filter.values.indexOf(item[filter.key]) === -1
        ) {
          result = false;
        }
      }
      item.StockDetailList = item.StockDetailList.filter(
        (item: StockDetailListItem) => {
          let result = true;
          for (const filter of stockListDetailItemFilters) {
            if (filter.values.length === 0) {
              continue;
            }
            if (
              item[filter.key] === undefined ||
              filter.values.indexOf(item[filter.key]) === -1
            ) {
              result = false;
            }
          }
          return result;
        }
      );
      if (item.StockDetailList.length === 0) {
        result = false;
      }
      return result;
    });
    return stockListFiltered;
  };

  // TODO: Move to Utils file
  // Hide toggle on filter items
  catToggle = (): void => {
    $(".js-Sidebar-catHeader").on("click", function click() {
      $(this)
        .find(".js-Sidebar-hideToggle")
        .toggleClass("makeMinus");
      $(this)
        .next(".js-Sidebar-toggleFields")
        .slideToggle("slow");
    });
  };

  createOptions = (
    list: StockListItem[],
    property: string
  ): DropdownItemProps[] => {
    const newList: DropdownItemProps[] = [];
    list.map((item: StockListItem) => {
      const tmpList = newList;
      if (
        tmpList.filter(i => i.value === item[property]).length === 0 &&
        item[property]
      ) {
        newList.push({ value: item[property], text: item[property] });
      }
    });
    return _.orderBy(newList, ["text"]);
  };

  createOptionsDetails = (
    list: StockListItem[],
    property: string
  ): DropdownItemProps[] => {
    const newList: DropdownItemProps[] = [];
    list.map((outerList: StockListItem, i: number) => {
      outerList.StockDetailList.map((innerList: StockDetailListItem) => {
        const tmpList = newList;
        if (
          tmpList.filter(item => item.value === innerList[property]).length ===
            0 &&
          innerList[property]
        ) {
          newList.push({
            value: innerList[property],
            text: innerList[property]
          });
        }
      });
    });
    return _.orderBy(newList, ["text"]);
  };

  getCurrentPageItems(list: StockListItem[]) {
    const temp = _.takeRight(
      list,
      list.length - this.state.pageLength * (this.state.pageCurrent - 1)
    );
    return _.take(temp, this.state.pageLength);
  }

  onExpandRow = (e: any, titleProps: any) => {
    let { index } = titleProps;
    if (index === this.state.activeIndex) {
      index = -1;
    }
    this.setState({ activeIndex: index });
  };

  onExcel = (list: StockListItem[]) => {
    return WarehouseService.stockInExcel(
      UserService.getSelectedCompany().PartnerCode,
      list
    );
  };

  onPage = (pageCurrent: number) => {
    this.setState({ pageCurrent });
  };

  setPageNumber = (pageLength: number) => {
    this.setState({ pageLength: pageLength ? pageLength : 10, pageCurrent: 1 });
  };

  onFilterChange = (filterKey: string, newValues: string[], text: string) => {
    const { stockListItemFilters, stockListDetailItemFilters } = this.state;

    const localItemFilters = _.cloneDeep(stockListItemFilters);
    const localDetailItemFilters = _.cloneDeep(stockListDetailItemFilters);

    const allFilters = localItemFilters.concat(localDetailItemFilters);
    const filter = allFilters.find(filter => {
      return filter.key === filterKey;
    });
    if (filter) {
      filter.values = newValues;

      this.setState({
        stockListItemFilters: localItemFilters,
        stockListDetailItemFilters: localDetailItemFilters,
        labels: onAdd(this.state.labels, filter.values, filterKey, text)
      });
    }
  };

  onLabelChange = (key: string) => {
    if (key) {
      this.onFilterChange(key, [], "");
    } else {
      this.clearFilters();
    }
  };

  clearFilters = () => {
    const { stockListItemFilters, stockListDetailItemFilters } = this.state;

    const localItemFilters = _.cloneDeep(stockListItemFilters);
    const localDetailItemFilters = _.cloneDeep(stockListDetailItemFilters);

    const allFilters = localItemFilters.concat(localDetailItemFilters);
    for (const filter of allFilters) {
      filter.values = [];
    }

    this.setState({
      stockListItemFilters: localItemFilters,
      stockListDetailItemFilters: localDetailItemFilters,
      labels: []
    });
  };

  getFilterValue = (key: string) => {
    const { stockListItemFilters, stockListDetailItemFilters } = this.state;
    const allFilters = stockListItemFilters.concat(stockListDetailItemFilters);
    const filter = allFilters.find(item => {
      return item.key === key;
    });
    if (filter) {
      return filter.values;
    }
    return [];
  };

  renderStockDetailListRow(item: StockDetailListItem) {
    return (
      <Table.Row key={item.PalletNo}>
        <Table.Cell>{item.HouseNo}</Table.Cell>
        <Table.Cell>{moment(item.InDate).format("DD.MM.YYYY")}</Table.Cell>
        <Table.Cell>{item.DocumentNumber}</Table.Cell>
        <Table.Cell>{item.ExternalDocumentNumber}</Table.Cell>
        <Table.Cell>{item.PalletNo}</Table.Cell>
        <Table.Cell>{item.ExternalPalletNo}</Table.Cell>
        <Table.Cell>{item.ExternalBatchNo}</Table.Cell>
        <Table.Cell>{item.Quantity}</Table.Cell>
        <Table.Cell>{item.NetWeight}kg</Table.Cell>
        <Table.Cell>
          {item.CustomerNo ? item.CustomerNo : item.InternalCustomerNo}
        </Table.Cell>
        <Table.Cell>{item.Building}</Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const {
      stockList,
      isLoading,
      activeIndex,
      sidebarOptions,
      pageLength,
      pageCurrent,
      labels
    } = this.state;
    const stockListFiltered: StockListItem[] = this.filterStockListItems(
      _.cloneDeep(stockList)
    );
    const currentPageItems = this.getCurrentPageItems(stockListFiltered);

    return (
      <Sidebar.Pushable as={Segment.Group} className={`${styles.page} h-100`}>
        <Sidebar.Pusher className="contentWrapper">
          <Row className={styles.pageHeader}>
            <PageHeader className="pageHeading">
              {languages("LABEL_STOCKLIST")}
            </PageHeader>
            <Row>
              <Col lg={10} md={9} sm={10}>
                <span className={styles.subtitle}>
                  <span className="ContentHeader-infoNumber">
                    {stockListFiltered.length}
                  </span>
                  {` ${languages("LABEL_PRODUCT_ID")}`}
                </span>
              </Col>
              <Col lg={2} md={3} sm={2}>
                <Download
                  title="Excel"
                  onDownload={() => this.onExcel(stockListFiltered)}
                  onError={() =>
                    SamskipNotify.displayWarning("ERROR_DOWNLOAD_FILE")
                  }
                  fileName={`Stocklist-${
                    UserService.getSelectedCompany().PartnerCode
                  }-${moment().format("D MMM YY")}`}
                  fileExtension="xlsx"
                  mimeType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <Button
                  basic
                  floated="right"
                  onClick={this.openSidebar}
                  icon="filter"
                />
              </Col>
            </Row>
            <Row>
              {labels && labels.length > 0 && (
                <Col lg={9} md={9} sm={9}>
                  <Labels
                    title={languages("LABEL_FILTERBY")}
                    clearText={languages("LABEL_CLEAR")}
                    onChange={(key: string) => this.onLabelChange(key)}
                    data={labels}
                  />
                </Col>
              )}
            </Row>
          </Row>

          {!isLoading && stockListFiltered.length > 0 ? (
            <React.Fragment>
              <Row className={`${styles.pager}`}>
                <Col className="pageNumbers" lg={3} md={3} sm={3}>
                  <PageNumbers
                    title={`${languages("TEXT_RECORDS_ON_PAGE")}:`}
                    list={[10, 25, 50, 100]}
                    selected={pageLength}
                    onClick={this.setPageNumber}
                  />
                </Col>
              </Row>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {languages("LABEL_PRODUCT_ID")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {languages("LABEL_PRODUCT_DESCRIPTION")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {languages("LABEL_PALLET_QTY")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {languages("LABEL_UNIT_QTY")}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Accordion as={Table.Body}>
                  {currentPageItems.map((item: StockListItem, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        <Accordion.Title
                          as={Table.Row}
                          index={i}
                          active={activeIndex === i}
                          onClick={this.onExpandRow}
                        >
                          <Table.Cell>{item.ExternalCustomerItemNo}</Table.Cell>
                          <Table.Cell>{item.Description}</Table.Cell>
                          <Table.Cell>{item.StockDetailList.length}</Table.Cell>
                          <Table.Cell>
                            {item.StockDetailList.reduce(
                              (sum: number, { Quantity }) => {
                                return sum + Quantity;
                              },
                              0
                            )}
                          </Table.Cell>
                        </Accordion.Title>
                        <Accordion.Content
                          as={Table.Row}
                          active={this.state.activeIndex === i}
                        >
                          <Table.Cell colSpan={4} className="innerTableWrapper">
                            <Table>
                              <Table.Body>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    {languages("LABEL_APPR_NO")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_RECEIPT_DATE")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_DOCUMENT_NO")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_EXT_DOCUMENT_NO")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_PALLET_NO")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_EXT_PALLET_NO")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_BATCH_NO")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_UNIT_QTY")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_WEIGHT")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_CUSTOMER_DEPARTMENT")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {languages("LABEL_BUILDING")}
                                  </Table.HeaderCell>
                                </Table.Row>
                                {item.StockDetailList &&
                                  item.StockDetailList.map(
                                    (detail: StockDetailListItem) => {
                                      return this.renderStockDetailListRow(
                                        detail
                                      );
                                    }
                                  )}
                              </Table.Body>
                            </Table>
                          </Table.Cell>
                        </Accordion.Content>
                      </React.Fragment>
                    );
                  })}
                </Accordion>
              </Table>
              <Row className={`${styles.pager}`}>
                <Col lg={12} md={12} sm={12}>
                  <Pager
                    firstLabel={languages("LABEL_FIRSTPAGE")}
                    prevLabel={languages("LABEL_PREVIOUSPAGE")}
                    nextLabel={languages("LABEL_NEXTPAGE")}
                    lastLabel={languages("LABEL_LASTPAGE")}
                    onPage={this.onPage}
                    page={pageCurrent}
                    count={stockListFiltered.length}
                    size={pageLength}
                  />
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
        </Sidebar.Pusher>
        <Sidebar
          className={`js-Sidebar ${styles.filter}`}
          animation="overlay"
          icon="labeled"
          visible={this.state.sidebarVisible}
          direction="right"
        >
          <Button onClick={this.closeSidebar} icon="chevron right" />
          <div className="js-Sidebar-catHeader">
            <span className="title">{languages("LABEL_PRODUCT_ID")}</span>
            <div className="js-Sidebar-hideToggle makeMinus">
              <span></span>
            </div>
          </div>
          <div className="js-Sidebar-toggleFields">
            <div className="global-addIcon">
              <Dropdown
                placeholder={languages("LABEL_PRODUCT_ID")}
                fluid
                search
                selection
                multiple
                clearable
                upward={false}
                options={sidebarOptions.customerItemNo}
                value={this.getFilterValue(
                  "ExternalCustomerItemNo"
                )}
                onChange={(e: any, d: any) => {
                  this.onFilterChange(
                    "ExternalCustomerItemNo",
                    d.value,
                    languages("LABEL_PRODUCT_ID")
                  );
                }}
              />
            </div>
          </div>
          <div className="js-Sidebar-catHeader">
            <span className="title">{languages("LABEL_APPR_NO")}</span>
            <div className="js-Sidebar-hideToggle makeMinus">
              <span></span>
            </div>
          </div>
          <div className="js-Sidebar-toggleFields">
            <div className="global-addIcon">
              <Dropdown
                placeholder={languages("LABEL_APPR_NO")}
                fluid
                search
                selection
                multiple
                clearable
                upward={false}
                options={sidebarOptions.houseNo}
                value={this.getFilterValue(
                  "HouseNo"
                )}
                onChange={(e: any, d: any) => {
                  this.onFilterChange(
                    "HouseNo",
                    d.value,
                    languages("LABEL_APPR_NO")
                  );
                }}
              />
            </div>
          </div>
          <div className="js-Sidebar-catHeader">
            <span className="title">{languages("LABEL_BATCH_NO")}</span>
            <div className="js-Sidebar-hideToggle makeMinus">
              <span></span>
            </div>
          </div>
          <div className="js-Sidebar-toggleFields">
            <div className="global-addIcon">
              <Dropdown
                placeholder={languages("LABEL_BATCH_NO")}
                fluid
                search
                selection
                multiple
                clearable
                options={sidebarOptions.externalBatchNo}
                value={this.getFilterValue(
                  "ExternalBatchNo"
                )}
                onChange={(e: any, d: any) => {
                  this.onFilterChange(
                    "ExternalBatchNo",
                    d.value,
                    languages("LABEL_BATCH_NO")
                  );
                }}
              />
            </div>
          </div>
          <div className="js-Sidebar-catHeader">
            <span className="title">
              {languages("LABEL_CUSTOMER_DEPARTMENT")}
            </span>
            <div className="js-Sidebar-hideToggle makeMinus">
              <span></span>
            </div>
          </div>
          <div className="js-Sidebar-toggleFields">
            <div className="global-addIcon">
              <Dropdown
                placeholder={languages("LABEL_CUSTOMER_DEPARTMENT")}
                fluid
                search
                selection
                multiple
                clearable
                options={sidebarOptions.customerNo}
                value={this.getFilterValue(
                  "CustomerNo"
                )}
                onChange={(e: any, d: any) => {
                  this.onFilterChange(
                    "CustomerNo",
                    d.value,
                    languages("LABEL_CUSTOMER_DEPARTMENT")
                  );
                }}
              />
            </div>
          </div>
        </Sidebar>
        <Spinner className={styles.spinner} isHidden={!isLoading} />
        {!isLoading && !stockListFiltered.length && (
          <h1 className="noResults">{languages("TEXT_NORESULTS")}</h1>
        )}
      </Sidebar.Pushable>
    );
  }
}
